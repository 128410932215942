import { graphql } from 'gatsby'
import React, { ReactElement, useState } from 'react'
import './OfficeAppointment.scss'
import Layout from '../../layouts/Layout/Layout'
import Seo from '../../components/Seo/Seo'
import { LazyLoadImage } from 'react-lazy-load-image-component'

type OfficeAppointmentTypes = {
  title: string
  featuredImage: string
  description: string
  slug: string
  steps: {
    description: string
    image: string
  }[]
}

type OfficeAppointmentPropTypes = {
  data: {
    officeAppointment: OfficeAppointmentTypes
    officeAppointmentLink?: {
      url: string
    }
  }
}

const OfficeAppointment = ({
  data: { officeAppointment, officeAppointmentLink },
}: OfficeAppointmentPropTypes): ReactElement => {
  const [title] = useState(
    officeAppointment.title || 'How to Schedule an Appointment',
  )

  return (
    <Layout>
      <Seo
        jsonData={{
          url: `${process.env.GATSBY_SITE_URL}/office-appointment/`,
          description:
            'Donec facilisis tortor ut augue lacinia, at viverra est semper. Sed sapien metus,scelerisque nec pharetra id, tempor a tortor. Pellentesq.',
          keywords:
            'properties, condo, philippines, house and lot, avidaland, avida, apartment, book appointment, book an office appointment, office appointment',
        }}
        title={title}
      />
      <div className="office-appointment-container">
        <div className="office-appointment">
          <div className="office-appointment-header">
            <div className="office-appointment-title-container">
              <h2>We’re Ready to Serve You</h2>
              <a
                href={officeAppointmentLink?.url || ''}
                target="_blank"
                rel="noreferrer"
                className="office-appointment-book-now-button"
              >
                Book Now
              </a>
            </div>
            {!!officeAppointment.featuredImage && (
              <LazyLoadImage
                src={officeAppointment.featuredImage}
                className="office-appointment-banner"
                alt="banner image"
              />
            )}
          </div>
          <div className="office-appointment-content-container">
            <p className="office-appointment-description">
              {officeAppointment.description}
            </p>
            <h3 className="office-appointment-steps-title">{title}</h3>
            <ul className="office-appointment-steps">
              {officeAppointment.steps.map((step) => (
                <li key={step.description}>
                  <img src={step.image} alt="step image" />
                  <p>{step.description}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default OfficeAppointment

export const pageQuery = graphql`
  query {
    officeAppointment {
      ...OfficeAppointmentPageMainFields
    }
    officeAppointmentLink: link(
      category: { eq: "external" }
      name: { eq: "book_an_office_appointment" }
    ) {
      ...LinkFields
    }
  }
`
